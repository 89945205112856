<div class="login-box">
    <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
    <div class="login-box-body">
        <p class="login-box-msg">{{ config.messageOverrides.loginLabel }}</p>
        <div *ngIf="!config.hideRegularSignIn">
            <form *ngIf="loginForm" [formGroup]="loginForm" (submit)="onLogin()">
                <div class="form-group has-feedback" [class.has-error]="isControlInvalid('username', 'required')">
                    <input type="text" autofocus class="form-control" placeholder="Username" formControlName="username" />
                    <span class="fa fa-user form-control-feedback"></span>
                    <div *ngIf="isControlInvalid('username', 'required')" class="small errortext" [style.position]="'absolute'">
                        Username is required
                    </div>
                </div>
                <div class="form-group has-feedback" [class.has-error]="isControlInvalid('password', 'required')">
                    <input #Password type="password" autocomplete="off" class="form-control" placeholder="Password" formControlName="password" />
                    <span class="fa fa-lock form-control-feedback"></span>
                    <div *ngIf="isControlInvalid('password', 'required')" class="small errortext" [style.position]="'absolute'">
                        Password is required
                    </div>
                </div>
                <div class="padded block-parent">
                    <div [ngSwitch]="rememberOption" class="form-check">
                        <div *ngSwitchCase="'rememberMe'">
                            <input type="checkbox" id="rememberMe" class="form-check-input" formControlName="rememberMe" />
                            <label class="form-check-label" for="rememberMe"> Remember Me</label>
                        </div>
                        <div *ngSwitchCase="'userName'">
                            <input type="checkbox" id="rememberUserName" class="form-check-input" formControlName="rememberUserName" />
                            <label class="form-check-label" for="rememberUserName"> Remember My Username</label>
                        </div>
                        <div *ngSwitchDefault></div>
                    </div>
                    <button [disabled]="!loginForm.valid" type="submit" class="btn btn-primary btn-flat inline-block block-right">
                        {{ config.messageOverrides.signInButtonText }}
                    </button>
                </div>
            </form>
            <br />

            <ng-container *ngFor="let link of config.loginComponentLinks">
                <div style="padding-left: 15px">
                    <span style="cursor: pointer" [innerHtml]="link.linkHtml" [routerLink]="link.routerLinkPath"></span><br />
                </div>
            </ng-container>
            <ng-container>
                <div style="padding-left: 15px">
                    <b style="color: rgba(34, 34, 34, 1); font-family: Calibri, sans-serif; font-size: 14.6667px">
                        <span style="font-size: 13.5pt; color: rgba(255, 70, 0, 1)">AMO Members and Applicants</span>
                    </b>
                    <span style="font-family: Calibri, sans-serif; font-size: 13.5pt; color: rgba(255, 70, 0, 1)">
                        : If this is your first time logging into the new AMO Member Portal, your existing credentials for the previous AMO
                        Dispatching site will not work. For security reasons, you will need to reset your credentials and re-register your online
                        account with AMO. Please&nbsp;
                    </span>
                    <b style="color: rgba(34, 34, 34, 1); font-family: Calibri, sans-serif; font-size: 14.6667px">
                        <u>
                            <span style="font-size: 13.5pt; color: rgba(35, 62, 112, 1); cursor: pointer" [routerLink]="'/createMember'">
                                CLICK HERE
                            </span>
                        </u>
                    </b>
                    <span style="font-family: Calibri, sans-serif; font-size: 13.5pt; color: rgba(35, 62, 112, 1)"> &nbsp; </span>
                    <span style="font-family: Calibri, sans-serif; font-size: 13.5pt; color: rgba(255, 70, 0, 1)"> to do so.&nbsp; </span>
                    <span style="font-family: Calibri, sans-serif; font-size: 13.5pt; color: rgba(0, 0, 0, 1)"> &nbsp; </span>
                    <br />
                </div>
            </ng-container>
        </div>

        <div class="text-center third-party-buttons">
            <div *ngIf="hasGoogleAuthConfig">
                <br />
                <mt-google-login [config]="config"></mt-google-login>
            </div>
            <div *ngIf="showMFA">
                <br />
                <mt-ms-login [config]="config"></mt-ms-login>
            </div>
            <div *ngIf="showSwitch">
                <br />
                <button class="btn btn-flat btn-success" (click)="switchLogins()">
                    {{ switchLabel }}
                </button>
            </div>
        </div>
    </div>
</div>
