import { Component, ErrorHandler, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@mt-ng2/auth-module';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { LoginConfig } from '@mt-ng2/login-module';
import { MemberService } from '../../services/members.service';

@Component({
    selector: 'app-reset-password-override',
    templateUrl: './reset-password-override.component.html',
})
export class ResetPasswordOverrideComponent implements OnInit {
    resetPasswordForm: UntypedFormGroup;
    logoFull: string;
    doubleClickIsDisabled = false;
    userId: number;
    resetKey: string;
    mrnNo: string;
    isMember = false;

    protected authService: AuthService;
    protected memberService: MemberService;
    protected router: Router;
    public route: ActivatedRoute;
    protected notificationsService: NotificationsService;
    protected environmentService: EnvironmentService;
    public config: LoginConfig;
    protected globalErrorHandler: ErrorHandler;

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.memberService = injector.get(MemberService);

        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
        this.notificationsService = injector.get(NotificationsService);
        this.environmentService = injector.get(EnvironmentService);
        this.config = injector.get(LoginConfig);
        this.globalErrorHandler = injector.get(ErrorHandler);
        this.logoFull = this.config.useAssetsFolderForImages
            ? `${this.environmentService.config.assetsPath}logo-full.png`
            : `${this.environmentService.config.imgPath}logo-full.png`;

        this.resetPasswordForm = new UntypedFormGroup({
            ConfirmPassword: new UntypedFormControl('', [Validators.required, Validators.pattern(this.config.passwordPattern)]),
            Password: new UntypedFormControl('', [Validators.required, Validators.pattern(this.config.passwordPattern)]),
        });
    }

    ngOnInit(): void {
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the login page.  Because you aren't authed!
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        const queryParams = this.route.snapshot.queryParams;
        this.resetKey = queryParams.resetKey;
        this.userId = +queryParams.userId;
        this.isMember = +queryParams.isMember > 0;

        if (this.isMember) {
            this.resetPasswordForm.addControl('MRN', new UntypedFormControl('', [Validators.required]));
        }
    }

    passwordHasError(): boolean {
        const control = this.resetPasswordForm.get('Password');
        return control.errors && (control.touched || control.errors.maxlength);
    }

    showPasswordRequiredError(): boolean {
        const control = this.resetPasswordForm.get('Password');
        return control.touched && control.hasError('required');
    }

    showConfirmPasswordRequiredError(): boolean {
        const control = this.resetPasswordForm.get('ConfirmPassword');
        return control.touched && control.hasError('required');
    }

    showMRNRequiredError(): boolean {
        const control = this.resetPasswordForm.get('MRN');
        return control.touched && control.hasError('required');
    }

    showPasswordMustMatchError(): boolean {
        const control = this.resetPasswordForm.get('Password');
        const controlValue = control.value;
        const confirmControl = this.resetPasswordForm.get('ConfirmPassword');
        const confirmControlValue = confirmControl.value;
        return control.touched && confirmControl.touched && controlValue.length && confirmControlValue.length && controlValue !== confirmControlValue;
    }

    hasRegexError(): boolean {
        let answer = false;
        const passwordControl = this.resetPasswordForm.controls.Password;
        if (passwordControl.errors?.pattern) {
            answer = true;
            passwordControl.setErrors({ pattern: true });
        }
        return answer;
    }

    onSubmit(): void {
        const form = this.resetPasswordForm;
        if (this.authService.matchPassword(form, null)) {
            if (form.valid) {
                if (this.isMember) {
                    this.memberService.validateMRN(this.userId, form.value.MRN as string).subscribe((validated) => {
                        if (validated) {
                            this.resetPassword(form);
                        } else {
                            this.error('The Mariners Reference Number entered does not match our records.');
                        }
                    });
                } else {
                    this.resetPassword(form);
                }
            } else {
                markAllFormFieldsAsTouched(form);
            }
        } else {
            this.error('Passwords do not match');
        }
    }

    resetPassword(form: UntypedFormGroup): void {
        this.authService.resetPassword(this.userId, form.value.Password as string, form.value.ConfirmPassword as string, this.resetKey).subscribe(
            (answer) => {
                this.success();
                void void this.router.navigate(['/home']);
            },
            (error) => {
                if (error.status === 418) {
                    this.notificationsService.error(LoginConfig.signInFailure);
                } else if (error.status === 400) {
                    if (error.error) {
                        if (typeof error.error === 'string') {
                            this.error(error.error as string);
                        } else if (typeof error.error?.ModelState?.Service === 'string') {
                            this.error(error.error.ModelState.Service as string);
                        } else {
                            this.globalErrorHandler.handleError(error);
                        }
                    } else {
                        this.error('Something went wrong, Please generate a new reset email');
                    }
                }
            },
        );
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Save Failed');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Password Updated Successfully');
    }
}
