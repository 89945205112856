import { Component, Injector } from '@angular/core';
import { LoginComponent } from '@mt-ng2/login-module';
import { appPaths } from '../../default-routes/app-paths.library';

@Component({
    selector: 'app-member-login',
    templateUrl: './member-login.component.html',
})
export class MemberLoginComponent extends LoginComponent {
    showMFA = false;
    showSwitch = true;
    switchLabel = 'Switch to Admin Login';
    switchPath = appPaths.loginAdmin;
    errorMessage: string | null = null;

    constructor(injector: Injector) {
        super(injector);
    }

    switchLogins(): void {
        void this.router.navigate([this.switchPath]);
    }
    Login(): void {
        try {
            this.onLogin();
        } catch (error) {
            this.handleLoginError(error);
        }
    }

    handleLoginError(error: any): void {
        if (error.status === 401) {
            this.errorMessage = 'Invalid username or password.';
        } else if (error.status === 403) {
            this.errorMessage = 'Your account has been locked. Please try again later.';
        } else if (error.status === 500) {
            this.errorMessage = 'A server error occurred. Please try again later.';
        } else {
            this.errorMessage = 'An unknown error occurred. Please try again.';
        }
        this.notificationsService.error(this.errorMessage);
    }
}
