<div class="create-box">
    <div class="miles-form padded">
        <h4>AMO Member Account Registration</h4>
        <mt-dynamic-form [config]="formObject" ([isEditing])="true" (submitted)="formSubmitted($event)">
            <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
                Cancel
            </button>
        </mt-dynamic-form>
    </div>
</div>
