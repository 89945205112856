<div class="login-box">
    <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
    <div class="login-box-body">
        <p class="login-box-msg">
            {{ config.messageOverrides.resetPasswordLabel }}
        </p>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
            <div class="form-group has-feedback" [class.has-error]="passwordHasError()">
                <label>New Password</label
                ><i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-html="true"
                    data-placement="right"
                    [title]="config.messageOverrides.failedPattern"
                ></i>
                <input
                    #Password
                    type="password"
                    autofocus
                    autocomplete="off"
                    class="form-control"
                    placeholder="Password"
                    formControlName="Password"
                />
                <span class="fa fa-lock form-control-feedback"></span>
                <div *ngIf="showPasswordRequiredError()" class="small errortext" [style.position]="'block'">Password is required</div>
                <div *ngIf="showPasswordMustMatchError()" class="small errortext" [style.position]="'block'">Passwords must match</div>
                <div *ngIf="hasRegexError()" class="small errortext" [style.position]="'block'" id="failedPatternMessage">
                    {{ config.messageOverrides.failedPattern }}
                </div>
                <label>Confirm New Password</label>
                <div class="form-group has-feedback" [class.has-error]="showConfirmPasswordRequiredError()">
                    <input
                        #PasswordConfirm
                        type="password"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Confirm Password"
                        formControlName="ConfirmPassword"
                    />
                    <span class="fa fa-lock form-control-feedback"></span>
                    <div *ngIf="showConfirmPasswordRequiredError()" class="small errortext" [style.position]="'block'">
                        Secondary Password is required
                    </div>
                </div>
                <div *ngIf="isMember">
                    <label>MRN Number</label>
                    <div class="form-group has-feedback" [class.has-error]="showMRNRequiredError()">
                        <input
                            #MRN
                            type="password"
                            autocomplete="off"
                            class="form-control"
                            placeholder="MRN Number"
                            formControlName="MRN"
                        />
                        <span class="fa fa-lock form-control-feedback"></span>
                        <div *ngIf="showMRNRequiredError()" class="small errortext" [style.position]="'block'">
                            MRN Number is required
                        </div>
                    </div>
                </div>
                <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">
                    Save
                </button>
                <a routerLink="/login" class="btn btn-default pull-right"> Go Home </a>
            </div>
        </form>
    </div>
    <!-- /.login-box-body -->
</div>
<!-- /.login-box -->

