import { Component, Injector } from '@angular/core';
import { LoginComponent } from '@mt-ng2/login-module';
import { appPaths } from '../../default-routes/app-paths.library';

@Component({
    selector: 'app-admin-login',
    templateUrl: './admin-login.component.html',
})
export class AdminLoginComponent extends LoginComponent {
    showRegularSignin = false;
    showSwitch = true;
    switchLabel = 'Switch to Member Login';
    switchPath = appPaths.login;

    constructor(injector: Injector) {
        super(injector);
    }

    switchLogins(): void {
        void this.router.navigate([this.switchPath]);
    }
}
