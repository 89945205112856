<div class="miles-card padded">
    <h4>Member Actions</h4>

    <div class="hidden-sm hidden-xs">
        <div id="btn-wrapper">
            <div class ="row">
                <div class ="col-md-3 button-md">
                    <button
                        type="button"
                        Class="btn btn-flat btn-success"
                        (click)="getMemberProfile()" 
                        [ngClass]="{
                            'big-btn': bigButtons
                        }"
                        [disabled]="!memberProfileActionButtonReady"
                    >
                        My Profile
                    </button>
                </div>
                <div class ="col-md-3 button-md">
                    <button
                    type="button"
                    Class="btn btn-flat btn-success"
                    (click)="getMemberStatement()" 
                    [ngClass]="{
                        'big-btn': bigButtons
                    }"
                    [disabled]="!memberStatementActionButtonReady"
                    >
                        Dues/Initiation
                    </button>
                </div>
                <div class ="col-md-3 button-md">
                    <button
                        type="button"
                        Class="btn btn-flat btn-success"
                        (click)="goToDeepSea()" 
                        [ngClass]="{
                            'big-btn': bigButtons
                        }"
                    >
                        Deep-Sea Jobs
                    </button>
                </div>
                <div class ="col-md-3 button-md">
                    <button
                        type="button"
                        Class="btn btn-flat btn-success"
                        (click)="goToGreatLakes()" 
                        [ngClass]="{
                            'big-btn': bigButtons
                        }"
                    >
                        Great Lakes Jobs
                    </button>
                </div>
            </div>
        </div>
    
        <div id="btn-wrapper">
            <div class ="row">
                <div class ="col-md-3 button-md">
                    <button
                    type="button"
                    Class="btn btn-flat btn-success"
                    (click)="getOnlinePayments()" 
                    [ngClass]="{
                        'big-btn': bigButtons
                    }"
                    >
                        Online Payments
                    </button>
                </div>
                <div class ="col-md-3 button-md">
                    <button
                        type="button"
                        Class="btn btn-flat btn-success"
                        (click)="scrollToOnlineForms()" 
                        [ngClass]="{
                            'big-btn': bigButtons
                        }"
                    >
                        Online Forms
                    </button>
                </div>
                <div class ="col-md-3 button-md">
                    <button
                        type="button"
                        Class="btn btn-flat btn-success"
                        (click)="scrollToOnlineDocuments()" 
                        [ngClass]="{
                            'big-btn': bigButtons
                        }"
                    >
                        Union Reports and Documents
                    </button>
                </div>
                <div class ="col-md-3 button-md">
                    <button
                        type="button"
                        Class="btn btn-flat btn-success"
                        (click)="goToSecureUpload()" 
                        [ngClass]="{
                            'big-btn': bigButtons
                        }"
                    >
                        Secure File Upload
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="hidden-md hidden-lg hidden-xl">
        <div id="btn-wrapper">
            <div class ="row">
                <div class ="col-sm-6 button-md">
                    <button
                        type="button"
                        Class="btn btn-flat btn-success"
                        (click)="getMemberProfile()" 
                        [ngClass]="{
                            'big-btn': bigButtons
                        }"
                        [disabled]="!memberProfileActionButtonReady"
                    >
                        My Profile
                    </button>
                </div>
                <div class ="col-sm-6 button-md">
                    <button
                    type="button"
                    Class="btn btn-flat btn-success"
                    (click)="getMemberStatement()" 
                    [ngClass]="{
                        'big-btn': bigButtons
                    }"
                    [disabled]="!memberStatementActionButtonReady"
                    >
                        Dues/Initiation
                    </button>
                </div>
            </div>
        </div>
        <div id="btn-wrapper">
            <div class ="row">
                <div class ="col-sm-6 button-md">
                    <button
                        type="button"
                        Class="btn btn-flat btn-success"
                        (click)="goToDeepSea()" 
                        [ngClass]="{
                            'big-btn': bigButtons
                        }"
                    >
                        Deep-Sea Jobs
                    </button>
                </div>
                <div class ="col-sm-6 button-md">
                    <button
                        type="button"
                        Class="btn btn-flat btn-success"
                        (click)="goToGreatLakes()" 
                        [ngClass]="{
                            'big-btn': bigButtons
                        }"
                    >
                        Great Lakes Jobs
                    </button>
                </div>
            </div>
        </div>
    
        <div id="btn-wrapper">
            <div class ="row">
                <div class ="col-sm-6 button-md">
                    <button
                    type="button"
                    Class="btn btn-flat btn-success"
                    (click)="getOnlinePayments()" 
                    [ngClass]="{
                        'big-btn': bigButtons
                    }"
                    >
                        Online Payments
                    </button>
                </div>
                <div class ="col-sm-6 button-md">
                    <button
                        type="button"
                        Class="btn btn-flat btn-success"
                        (click)="scrollToOnlineForms()" 
                        [ngClass]="{
                            'big-btn': bigButtons
                        }"
                    >
                        Online Forms
                    </button>
                </div>
            </div>
        </div>
        <div id="btn-wrapper">
            <div class ="row">
                <div class ="col-sm-6 button-md">
                    <button
                        type="button"
                        Class="btn btn-flat btn-success"
                        (click)="scrollToOnlineDocuments()" 
                        [ngClass]="{
                            'big-btn': bigButtons
                        }"
                    >
                        Union Reports and Documents
                    </button>
                </div>
                <div class ="col-sm-6 button-md">
                    <button
                        type="button"
                        Class="btn btn-flat btn-success"
                        (click)="goToSecureUpload()" 
                        [ngClass]="{
                            'big-btn': bigButtons
                        }"
                    >
                        Secure File Upload
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
