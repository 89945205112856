import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface IMRNValidationPayload {
    mrnNumber: string;
}

@Injectable({
    providedIn: 'root',
})
export class MemberService {
    constructor(public http: HttpClient) {}

    // getMemberInfo(): Observable<any> {
    //     return this.http.post<any>(`/members/info`, {});
    // }

    validateMRN(userId: number, mrnNumber: string): Observable<boolean> {
        const payload: IMRNValidationPayload = {
            mrnNumber: mrnNumber,
        };
        return this.http.post<boolean>(`/members/${userId}/validate/mrn`, payload);
    }

}
