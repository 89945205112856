import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentHelper } from '@common/document-helper';
import { PrizmReportService } from '@common/services/prizm-report.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-member-actions',
    styles: [
        `
            .big-btn {
                width: 220px;
                height: 80px;
                font-size: large;
                white-space: normal;
                word-wrap: break-word;
            }

            #btn-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .button-md {
                padding-bottom: 16px;
            }
        `,
    ],
    templateUrl: './member-actions.component.html',
})
export class MemberActionsComponent {
    @Input('bigButtons') bigButtons = false;
    @Input('name') name = '';
    date = new Date();

    get dateForName(): string {
        const mm = this.date.getMonth() + 1;
        const dd = this.date.getDate();

        return [this.date.getFullYear(),
                (mm > 9 ? '' : '0') + mm.toString(),
                (dd > 9 ? '' : '0') + dd.toString(),
                ].join('');
    }

    constructor(
        private prizmReportService: PrizmReportService,
        private notificationsService: NotificationsService,
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {}

    profileFileName: string;
    memberStatementFileName: string;
    memberProfileActionButtonReady = false;
    memberStatementActionButtonReady = false;

    ngOnInit(): void {
        forkJoin([
            this.prizmReportService.getMemberProfilePdf(),
            this.prizmReportService.getMemberStatementPdf()
        ])
        .subscribe(([profileResp, memberStatementResp]) => {
            if (profileResp.body.Name) {
                this.profileFileName = profileResp.body.Name;
                this.memberProfileActionButtonReady = true;
            }
            if (memberStatementResp.body.Name) {
                this.memberStatementFileName = memberStatementResp.body.Name;
                this.memberStatementActionButtonReady = true;
            }
            this.cdr.markForCheck();
            this.cdr.detectChanges();
        });
    }

    getMemberProfile(): void {
        DocumentHelper.openPdfByName(this.profileFileName);
    }

    getMemberStatement(): void {
        DocumentHelper.openPdfByName(this.memberStatementFileName);
    }

    goToSecureUpload(): void {
        window.open( `https://amounion.sharefile.com/remote/5a977651-9f6f-47a5-bc1f-1f30761f1913`, '_blank');
    }

    goToDeepSea(): void {
        void this.router.navigate(['/jobs/deep-sea']);
    }

    goToGreatLakes(): void {
        void this.router.navigate(['/jobs/great-lakes']);
    }

    getOnlinePayments(): void {
        void this.router.navigate(['/pay-dues']);
    }

    scrollToOnlineForms(): void {
        const element = document.getElementById(`OnlineForms`);
        if (element === null) {
            this.notificationsService.error('This post hasn`t been tagged yet.');
        } else {
            element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }

    scrollToOnlineDocuments(): void {
        const element = document.getElementById(`URD`);
        if (element === null) {
            this.notificationsService.error('This post hasn`t been tagged yet.');
        } else {
            element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }
}
