// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=testing` then `environment.testing.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    apiVersion: '1',
    appName: 'Member Portal',
    appVersion: '1.0.0',
    assetsPath: 'assets/',
    authClientId: 1,
    baseApiUrl: '',
    docPath: 'docs/',
    googleApiKey: '', // Empty this string to disable google login
    imgPath: 'docs/images/',
    logger: false,
    msAuthConfig: {
        authority: 'https://login.microsoftonline.com/common',
        clientId: '5a8dbabb-4497-49d4-b203-bdec4b853b93',
        redirectUri: 'https://american-maritime-officers.testing.milesapp.com',
    },
    payPalClientId: 'AZFtueIkcAiS-aOTzAqA2n-7Bd9TVXpSgkVt7zY3vaHDJkCcNwH4AbP42BNk_K7rq-a403j4NodEeHJO',
    production: false,
    siteName: 'AMO Member Portal',
    submitHelpUrl: 'http://submit-help.testing.milesapp.com',
    wordpressURL: 'https://www.amo-union.org',
};
