import { Validators } from '@angular/forms';
import { DynamicField } from '@mt-ng2/dynamic-form';
import { IUserDynamicControlsParameters, UserDynamicControls } from '../form-controls/user.form-controls';
import { IUser } from '../interfaces/user';

export class UserDynamicControlsPartial extends UserDynamicControls {

    constructor(userPartial?: IUser, additionalParameters?: IUserDynamicControlsParameters) {
        super(userPartial, additionalParameters);

        (<DynamicField>this.Form.MarinerReferenceNumber).validation = [ Validators.required ];
        (<DynamicField>this.Form.MarinerReferenceNumber).validators = { 'required': true };

        (<DynamicField>this.Form.BookingNumber).labelHtml = `<label>Book/Card Number (with no hyphen/dash) OR<label>`;
        (<DynamicField>this.Form.ApplicantNumber).labelHtml = `<label>Applicant Number (with no hyphen/dash)<label>`;


    }
}
