import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIService } from '@common/api-service';
import { IMetaItem } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PrizmReportService extends APIService {

    constructor(public http: HttpClient) {
        super('', http);
    }

    getMemberProfilePdf(): Observable<HttpResponse<IMetaItem>> {
        return this.http.get<IMetaItem>(`/prizm-reports/member-profile`, {observe: 'response'});
    }

    getMemberStatementPdf(): Observable<HttpResponse<IMetaItem>> {
        return this.http.get<IMetaItem>(`/prizm-reports/member-statement`, {observe: 'response'});
    }
}
