import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IPhone } from '@model/interfaces/base';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { IUser } from '@model/interfaces/user';
import { BaseService } from '@mt-ng2/base-service';
import { IAddress } from '@mt-ng2/dynamic-form';

@Injectable({
    providedIn: 'root',
})
export class UserService extends BaseService<IUser> {
    private emptyUser: IUser = {
        ApplicantNumber: null,
        AuthUserId: 0,
        BookingNumber: null,
        Email: null,
        FirstName: null,
        Id: 0,
        LastName: null,
        MarinerReferenceNumber: null,
        Version: null,
    };
    constructor(public http: HttpClient) {
        super('/users', http, null, { entityName: 'User' });
    }
    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }
    createUser(data: ICreateUserPayload): Observable<number> {
        return this.http.post<number>(`/users/create`, data);
    }
    createMember(data: ICreateUserPayload): Observable<number> {
        return this.http.post<number>(`/users/member`, data);
    }
    saveAddress(userId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/users/${userId}/address`, address);
        }
        else {
            return this.http.put<number>(`/users/${userId}/address`, address);
        }
    }
    deleteAddress(userId: number): Observable<object> {
        return this.http.delete(`/users/${userId}/address`, {
            responseType: 'text' as 'json',
        });
    }
    savePhones(userId: number, phones: IPhone): any {
        return this.http.put<number>(`/users/${userId}/phones`, phones);
    }
    savePhoto(userId: number, photo: File): any {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);
        return this.http.post(`/users/${userId}/pic`, formData);
    }
    deletePhoto(userId: number): any {
        return this.http.delete(`/users/${userId}/pic`);
    }
    formatTitleText(entity: IUser): void {
        this.setTitle(`User: ${entity.FirstName} ${entity.LastName}`);
    }
}
