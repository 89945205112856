export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Posts = 3,
    Jobs = 4,
    PrizmPdfs = 5,
    Audit = 6,
    AccessRequests = 7,
    PayDues = 8,
    EditorSettings = 9,
}
