import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { UserRoles } from '@model/enums/user-roles.enum';
import { IAuthUser } from '@model/interfaces/auth-user'; // added
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { IUser } from '@model/interfaces/user';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { AuthUserDynamicConfig } from '../../auth-entity/auth-user/auth-user.dynamic-config';
import { UserService } from '../../users/services/user.service';
import { CreateUserDynamicConfig } from './create-user.dynamic-config';

@Component({
    selector: 'app-create-user',
    styles: [
        `
            .create-box {
                padding: 5% 20% 5% 20%;
            }

        `,
    ],
    templateUrl: './create-user.component.html',
})
export class CreateUserComponent implements OnInit {
    user: IUser = this.userService.getEmptyUser();
    authUser: IAuthUser;
    additionalConfigs: any[] = [];
    isEditing: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    isHovered: boolean;
    config: any = {};
    userform: FormGroup;
    formFactory: CreateUserDynamicConfig<IUser>;
    doubleClickIsDisabled = false;
    isCurrentUser = false;

    constructor(
        private userService: UserService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;

        this.setConfig();
    }

    private isNewUser(): boolean {
        return this.user?.Id > 0 ? false : true;
    }

    getAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        const configControls: string[] = ['Password', 'ConfirmPassword', 'Username'];
        const authUser = this.isNewUser() ? null : this.user.AuthUser;
        const config = new AuthUserDynamicConfig<IAuthUser>(authUser, null, configControls);
        return [config];
    }

    setConfig(): void {
        this.formFactory = new CreateUserDynamicConfig<IUser>(this.user);
        this.additionalConfigs = this.getAdditionalConfigs();
        const config = this.isNewUser() ? this.formFactory.getForCreate(this.additionalConfigs) : this.formFactory.getForUpdate();
        this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = config.formObject?.map((x) =>new DynamicField(x));
        this.isEditing = this.isNewUser();
    }

    cancelClick(): void {
        void this.router.navigate(['/login']);
    }

    formSubmitted(form: UntypedFormGroup): void {
        const passwordmatch = this.authService.matchPassword(form);
        const mandatoryFields = form.value.User.ApplicantNumber.length > 0 || form.value.User.BookingNumber.length > 0;

        if (form.valid && passwordmatch && mandatoryFields) {
            this.newUserSave(form);
        } else {
            if (!passwordmatch) {
                this.error('Passwords do not match');
            } else if (!mandatoryFields) {
                this.error('Either an Applicant Number or a Booking Number are required');
            } else {
                markAllFormFieldsAsTouched(form);
                this.error();
            }
        }
    }

    private newUserSave(form: UntypedFormGroup): void {
        this.formFactory.assignFormValues(this.user, form.value.User as IUser);
        const data: ICreateUserPayload = {
            Password: form.value.AuthUser.Password,
            SendEmail: false,
            User: this.user,
            Username: form.value.AuthUser.Username,
            UserRoleId: UserRoles.Member,
        };
        // handle new member save
        this.userService
            .createMember(data)            .subscribe((answer) => {
                void this.router.navigate(['/login']);
                this.userService.emitChange(this.user);
                this.success();
            });
    }


    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Registration failed.  Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Registrated Successfully');
    }
}
