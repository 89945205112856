import { Injectable } from "@angular/core";
import { environment } from "@environment";
import { IDocument } from "@mt-ng2/entity-components-documents";

@Injectable({ providedIn: 'root' })
export class DocumentHelper {

    public static openPdf(document: IDocument): void {
        const name = document.Name;
        const typeIndex = name.indexOf('.');
        const type = name.substring(typeIndex);

        if (type === '.pdf') {
            DocumentHelper.openPdfByName(document.FilePath);
        }
    }

    public static openPdfByName(name: string): void {
        const redirectUri = environment.msAuthConfig.redirectUri;
        const docPath = environment.docPath.includes('http') ? environment.docPath : redirectUri + '/' + environment.docPath;
        const url = docPath + name;
        window.open(url, '_blank', '');
    }

}
