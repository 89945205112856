import { AuthUserDynamicControls } from '../form-controls/auth-user.form-controls';
import { IAuthUser } from '../interfaces/auth-user';
import { IUserRole } from '../interfaces/user-role';
import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { LoginConfigOverride } from '@common/configs/login.config';
export class AuthUserDynamicControlsExtended extends AuthUserDynamicControls {
    constructor(private extAuthUser: IAuthUser, userRoles: IUserRole[], configControls: string[]) {
        super(extAuthUser, { roles: userRoles });

        let passwordRegexPattern = new RegExp(LoginConfigOverride.passwordPattern);
        let failedPatternMessage = LoginConfigOverride.messageOverrides.failedPattern;

        if (configControls.includes('SendResetEmail') && (!this.extAuthUser || this.extAuthUser.Id === 0)) {
            // Add the send email checkbox when creating a new user
            let sendEmail = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Send Password Reset Email',
                name: 'SendResetEmail',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                }),
                value: '',
            });
            this.Form.SendResetEmail = sendEmail;
        }

        if (configControls.includes('ConfirmPassword')) {
            // Add the controls to validate passwords.  These are not in the model.
            this.Form.ConfirmPassword = new DynamicField({
                failedPatternMessage: failedPatternMessage,
                formGroup: 'AuthUser',
                label: 'Confirm Password',
                labelHtml: `<label>Confirm Password</label>`,
                name: 'ConfirmPassword',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                }),
                validation: [Validators.required, Validators.pattern(passwordRegexPattern)],
                validators: {
                    pattern: passwordRegexPattern,
                    required: true,
                },
                value: '',
            });

            this.Form.CurrentPassword = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Current Password',
                name: 'CurrentPassword',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: '',
            });
        }

        this.Form.Username.label = 'Username (with no spaces)';
        this.Form.Username.labelHtml = '<b>Username (with no spaces)</b>';

        (<DynamicField>this.Form.Password).validation.push(Validators.pattern(passwordRegexPattern));
        (<DynamicField>this.Form.Password).validators.pattern = passwordRegexPattern;
        (<DynamicField>this.Form.Password).failedPatternMessage = failedPatternMessage;
    }
}
